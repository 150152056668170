// 首页活动配置 字段
export const operateColumns = [
  {
    title: '名称',
    dataIndex: 'name',
    key: 'name',
  },
   {
    title: '状态',
    dataIndex: 'state',
    key: 'state',
    width: 100,
    scopedSlots: { customRender: 'state' },
  },
  {
    title: '曝光次数',
    dataIndex: 'exposure',
    key: 'exposure',
    width: 100
  },
  {
    title: '点击人数',
    dataIndex: 'clicks',
    key: 'clicks',
    width: 100
  },
  {
    title: '开始时间',
    dataIndex: 'startTime',
    key: 'startTime',
  },
  {
    title: '结束时间',
    dataIndex: 'endTime',
    key: 'endTime',
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    width: '200px',
    scopedSlots: { customRender: 'action' },
  },
]

// 签到海报字段
export const signPosterColumns = [
  {
    title: '序号',
    dataIndex: 'number',
    key: 'number',
    scopedSlots: {customRender: 'number'},
  },
  {
    title: '海报图片',
    dataIndex: 'imageUrl',
    key: 'imageUrl',
    scopedSlots: {customRender: 'imageUrl'}
  },
  {
    title: '名称',
    dataIndex: 'name',
    key: 'name',
    width: '20%'
  },
  {
    title: '跳转方式',
    dataIndex: 'targetType',
    key: 'targetType',
    scopedSlots: {customRender: 'targetType'}
  },
  {
    title: '状态',
    dataIndex: 'state',
    key: 'state',
    scopedSlots: {customRender: 'state'}
  },
  {
    title: '日期',
    dataIndex: 'effectiveDate',
    key: 'effectiveDate',
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    width: '220px',
    scopedSlots: {customRender: 'action'}
  },
]